class PageInit extends HTMLElement {
  connectedCallback() {
    this._title = this.getAttribute('title');
    this._url = this.getAttribute('url');

    this.init();
  }

  init() {
    window.addEventListener('load', () => {
      this.updateTitle();

      requestIdleCallback(() => {
        this.initServiceWorker();
      });
    });
  }

  updateTitle() {
    document.title = `lamplightdev - ${this._title}`;
  }

  async initServiceWorker() {
    navigator.serviceWorker.register('/sw.js');

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload();
    });

    if (!navigator.serviceWorker.controller) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.active.postMessage({
          type: 'LOAD_CONTENT',
          url: this._url,
        });
      });
    } else {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration.waiting) {
        this.showUpdateAvailable();
      } else {
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          newWorker.addEventListener('statechange', () => {
            if (newWorker.state === 'installed') {
              this.showUpdateAvailable();
            }
          });
        });
      }
    }
  }

  showUpdateAvailable() {
    document
      .querySelector('snappy-update-indicator')
      .setAttribute('waiting', '');
  }
}

customElements.define('snappy-page-init', PageInit);
