class UpdateIndicator extends HTMLElement {
  static get observedAttributes() {
    return ['waiting'];
  }

  constructor() {
    super();

    this._connected = false;
    this._waiting = false;
  }

  connectedCallback() {
    this.innerHTML = `
      <style>
        snappy-update-indicator {
          contain: content;
          display: none;
          text-align: center;
          margin-bottom: 0.5rem;
          font-size: 1rem;
        }

        snappy-update-indicator[waiting] {
          display: block;
        }

        snappy-update-indicator button {
          padding: 0 0.3rem;
          border: 1px solid white;
          margin-left: 0.5rem;
        }
      </style>

      Update available: <button>update now</button>
    `;

    this.querySelector('button').addEventListener('click', () => {
      this.activateUpdate();
    });

    this._connected = true;
  }

  disconnectedCallback() {
    this._connected = false;
  }

  attributeChangedCallback(name, oldValue, newValue) {
    switch (name) {
      case 'waiting': {
        this._waiting = newValue !== null;
      }
    }
  }

  async activateUpdate() {
    const registration = await navigator.serviceWorker.getRegistration();
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  }
}

export default UpdateIndicator;
